window.addEventListener('heyflow-screen-view', (event) => {

console.log('heyflow screen view:', event.detail);
if(event.detail.screenName === 'hsntsn'){
let brands = document.getElementById('input-2fde0836');
let cars = document.getElementById('input-34f090da');
let brandslabel = document.querySelector('label[for="input-2fde0836"]');
let carslabel = document.querySelector('label[for="input-34f090da"]');
brandslabel.style.visibility = 'hidden';
carslabel.style.visibility = 'hidden';
brands.style.visibility = 'hidden';
cars.style.visibility = 'hidden';
console.log('heyflow screen view: if Condition', event.detail);
window.addEventListener('heyflow-change', (event) => {
console.log('heyflow change fieldsSimples:', event.detail.fieldsSimple);
console.log('heyflow change:', event.detail);
if(event.detail.fieldsSimple.HSN != undefined && event.detail.fieldsSimple.TSN != undefined){
console.log('event.detail.fieldsSimple.HSN',event.detail.fieldsSimple.HSN);
console.log('event.detail.fieldsSimple.TSN',event.detail.fieldsSimple.TSN);
let varhsn = event.detail.fieldsSimple.HSN;
let vartsn = event.detail.fieldsSimple.TSN;
let hsntsn = varhsn +'&'+vartsn;
const respone = fetch('https://computing-power-742--automobile.sandbox.my.salesforce-sites.com/Heyflow/services/apexrest/fetchVehecle/'+hsntsn) // api for the get request
.then(response =>{
 if(!response.ok){
     let brands = document.getElementById('input-2fde0836');
     let cars = document.getElementById('input-34f090da');
     
     // Labels
     let brandslabel = document.querySelector('label[for="input-2fde0836"]');
     let carslabel = document.querySelector('label[for="input-34f090da"]');
     brands.style.visibility='hidden';
     cars.style.visibility='hidden';
     
     brandslabel.style.visibility = 'hidden';
     carslabel.style.visibility = 'hidden';
     
     
     
     throw new Error(' response was not ok');
 }
 return response.json();
 
})
.then(data =>{

 console.log(data.Name);
 let brands = document.getElementById('input-2fde0836');
 let cars = document.getElementById('input-34f090da');
 // Labels
 let brandslabel = document.querySelector('label[for="input-2fde0836"]');
 let carslabel = document.querySelector('label[for="input-34f090da"]');
 
 brands.style.visibility='visible';
 cars.style.visibility='visible';
 
 brandslabel.style.visibility = 'visible';
 carslabel.style.visibility = 'visible';
 brands.value = data.Fabrikmarke__c;
 cars.value = data.Handelsname__c;
 
} );



/*console.log('jsonbody',respone)
let el_down = document.getElementById('input-49aa2f34');
console.log(el_down.value);
el_down.value = event.detail.fieldsSimple.HSN + ' ' + event.detail.fieldsSimple.TSN;
event.detail.fieldsSimple.Output = el_down.value;
console.log('heyflow change:', event.detail.fieldsSimple);*/
}
});
}
if(event.detail.screenName === 'liste'){
   
   
    var select = document.getElementById("id-620ded9c");
fetch("https://computing-power-742--automobile.sandbox.my.salesforce-sites.com/Heyflow/services/apexrest/fetchmaker/")
.then(response => response.json())
.then(data =>{
console.log(data.length)

data.forEach(function(d) {
    let option = document.createElement('option');
    option.value = d;
    option.textContent = d;
    select.appendChild(option);
});
 


} )
.catch(error => console.error(error));
console.log('heyflow screen view',event.detail);

// First Endpoint Hit End

// 2nd Endpoint Hit start
window.addEventListener('heyflow-change', (event) => {
    
     console.log('heyflow change fieldsSimples:', event.detail.fieldsSimple);
     console.log('heyflow change:', event.detail);
     var select = document.getElementById("id-620ded9c");
     console.log(select.value);
     if(select.value == 'None'){
         var newmodell = document.getElementById("id-970c6c5b");
         for ( var i = newmodell.length - 1; i >= 0 ; i--) {
               newmodell.options[i] = null;
             }
         }
      if(select.value != 'None'){
          var newmodell = document.getElementById("id-970c6c5b");
          for (i = newmodell.length - 1; i >= 0 ; i--) {
     
               newmodell.options[i] = null;
    
             }
           console.log(encodeURIComponent(select.value));
	       let encodedName = encodeURIComponent(select.value);
	       fetch("https://computing-power-742--automobile.sandbox.my.salesforce-sites.com/Heyflow/services/apexrest/fetchModell/?modell=" + encodedName)
	       .then(response => response.json())
	       .then(data => {
		    console.log(data)
		    var select = document.getElementById("id-970c6c5b");
		    console.log('Select',select);
		    data.forEach(function(d) {
            let option = document.createElement('option');
            option.value = d;
            option.textContent = d;
            select.appendChild(option);
            });

		 })
	 .catch(error => console.error(error));
     
          
      }
    
    
});





}





});

window.addEventListener('heyflow-submit', (event) => {
// console.log('heyflow submit:', event.detail);
});



/*window.addEventListener('heyflow-change', (event) => {

});*/